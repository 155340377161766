import React from 'react'
import CategoriesContainer from '../../components/CategoriesContainer'


export default function AllCategoriesPage() {
  return (
    <div>
      <CategoriesContainer />
    </div>
  )
}
