import React from 'react'
import Cart from '../../components/Cart'




export default function CartPage() {
  return (
    <div>
		  <Cart />
		  
    </div>
  )
}
