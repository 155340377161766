import React from 'react'
import notFound from '../../Media/ops..png'

export default function NotFoundPage() {
  return (
    <div>

<img src={notFound}
alt='NotFoundPage'/>	
</div>
  )
}
